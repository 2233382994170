<template>
    <v-row>
        <v-col v-if="cities.length">
            <v-chip-group
                v-model="filter.location"
                show-arrows
                center-active
                @change="$emit('search')"
            >
                <v-chip
                    v-for="(city, i) in cities"
                    :key="i"
                    :value="city.slug"
                    filter
                >
                    {{ city.name }}
                </v-chip>
            </v-chip-group>
        </v-col>

        <v-col v-if="developers.length">
            <v-chip-group
                v-model="filter.developer"
                show-arrows
                center-active
                @change="$emit('search')"
            >
                <v-chip
                    v-for="(developer, i) in developers"
                    :key="i"
                    :value="developer.slug"
                    filter
                >
                    {{ developer.name }}
                </v-chip>
            </v-chip-group>
        </v-col>

        <v-col>
            <v-chip-group
                v-model="filter.completion"
                show-arrows
                center-active
                @change="$emit('search')"
            >
                <v-chip value="ready" filter>
                    {{ $t('headers.ready') }}
                </v-chip>
                <v-chip
                    v-for="(year, i) in years"
                    :key="i"
                    :value="year"
                    filter
                >
                    {{ year }}
                </v-chip>
            </v-chip-group>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        years: [],
    }),

    computed: {
        ...mapGetters({
            isLoading: 'lists/loading',
            filter: 'project/filter',
            cities: 'lists/cities',
            developers: 'lists/developers',
        })
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.setYears()
            this.$store.dispatch('lists/fetchCities')
            this.$store.dispatch('lists/fetchDevelopers')
        },
        setYears() {
            for (let num = 0; num <= 10; num++) {
                this.years.push((new Date().getFullYear() + num).toString())
            }
        }
    },

    beforeDestroy() {
        this.$store.dispatch('project/resetFilter')
        this.$store.dispatch('lists/resetCities')
        this.$store.dispatch('lists/resetDevelopers')
    }
}
</script>
